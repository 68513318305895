

import {Card, CardContent, CardHeader, Chip, Grid, Typography} from "@mui/material";
import {Gesture} from "@mui/icons-material";

const DrawCards = (props) => {

    // map card objects to drawCards and return array of populated cards
    if (props.drawsData !== undefined) {
        let numDraws = props.drawsData.length
        return props.drawsData.map((draw, index) => {

            // convert amount requested to 2-digit USD format
            var requestedAmount = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2
            }).format(draw.requestedAmount)

            return(
                <Grid item key={index}>
                    <Card
                        item
                        raised={true}
                        variant='outlined'
                        sx={{
                            width: 270
                        }}
                        key={index}
                    >
                        <CardHeader
                            /*NOTE: the indexes need to be reversed to show the draw numbers correctly*/
                            subheader={<Typography variant='overline'>
                                {`Draw #${numDraws - index} ${index <= 0 ? '(latest)' : ''}`}
                            </Typography>}
                            avatar={<Gesture color={"primary"} />}
                            sx={{
                                paddingBottom: '1px'
                            }}
                        />

                        <CardContent>
                            <Grid
                                container
                                spacing={1}
                            >
                                <Grid item xs={12}>
                                    <Chip label={`Date Entered: ${new Date(draw.createdAt).toDateString()}`}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Chip label={`Amount Requested: ${requestedAmount}`}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )
        })
    }
}

export default DrawCards;