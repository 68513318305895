// react
import React, {useState} from "react";
// MUI
import {Box, Button, Modal, Typography} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 3
};

function DisclaimerModal(props) {

    const [isOpen, setIsOpen] = useState(true)

    const handleClick = () => {
        setIsOpen(false)
    }

    return(
        <Modal open={isOpen}>
            <Box sx={style}>
                <Box textAlign={"center"}>
                    <InfoOutlinedIcon sx={{mb: 2}}/>
                </Box>
                <Typography>{props.disclaimer}</Typography>
                <Box textAlign="center">
                    <Button
                        onClick={handleClick}
                        variant="contained"
                        sx={{mt: 3}}
                    >
                        I understand
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default DisclaimerModal;