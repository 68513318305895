
import * as React from 'react';
import LinearProgress, { LinearProgressProps, linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

//FIXME: the title of the funding source needs to come from the JSON data
//FIXME: the percentage needs to come from the JSON data

function calculateTotals(drawsData) {

    let totals = {
        totalBudget: 0.0,
        totalRequested: 0.0,
        percentDrawn: 0
    }

    // calculate total budget and requested amounts
    drawsData.forEach((draw) => {
        draw.lineItems.forEach((lineItem) => {
            totals.totalRequested += lineItem.requestedAmount
        })
    })

    drawsData[0].lineItems.forEach((lineItem) => {
        totals.totalBudget += lineItem.budgetAmount
    })

    // calculate percent-drawn
    totals.percentDrawn = (totals.totalRequested / totals.totalBudget) * 100

    return totals
}

const FundingSourceProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 6,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 100 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

function LinearProgressWithLabel(props: LinearProgressProps & { value: number}) {
    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <FundingSourceProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="button" color="white">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default function FundingSource(props) {

    const totals = calculateTotals(props.drawsData)

    // convert amount requested to 2-digit USD format
    var totalBudget = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(totals.totalBudget)
    var totalRequested = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(totals.totalRequested)

    return (
        <Box sx={{ maxWidth: '100%', m: 1, mb: 2}}>
            <LinearProgressWithLabel value={totals.percentDrawn} />
            <Typography color={"white"} variant={'caption'}>{`Current Budget: ${totalRequested} / ${totalBudget}`}</Typography>
        </Box>
    );
}