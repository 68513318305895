/*
* Name: Jeremy Noble
* Company: Quick Draw Fund Control, Inc.
* All rights reserved
* */

// React
import React, {useEffect, useState} from "react";
// MUI
import {Alert, AlertTitle, Chip, Grid, Tooltip, Typography} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CircularProgress from "@mui/material/CircularProgress";
// Local
import DrawCards from './DrawCards'
import FundingSource from './FundingSource'
import {getProjectData} from './GetProjectData'
import DisclaimerModal from "./DisclaimerModal";

/*
* App.js
* Brief:
* */
const App = () => {

    // state for managing client draw data
    const [drawsData, setDrawsData] = useState()
    const [projectData, setProjectData] = useState()

    // get companyId from query parameters
    const params = new URLSearchParams(window.location.search)
    const newCompanyId = params.get("companyId")
    // set clientId initial state
    const [companyId, setClientId] = useState(newCompanyId)
    // error state
    const [error, setError] = useState(
        {
            isError: false,
            errorMessage: ''
        }
    )

    const disclaimer = `This information was taken from your construction Schedule of Values and is only intended to 
    serve as a visual aid. Information here may not contain any additional fees required by your lender/grantor. 
    Quick Draw Fund Control, Inc. does not guarantee that this information is up to date and does accept any liability 
    as a result of providing this information. Progress percentage may not reflect actual project percent-complete.
    Please refer to your own documentation for accounting purposes.`

    // get draws data on App mount only
    useEffect(() => {
        // get draws data
        getProjectData(companyId, setDrawsData, setProjectData, setError)
    }, [companyId])

    useEffect(() => {
        // re-render if error status changes
    }, [error.isError])

    // check that draws data has been populated
    if (drawsData !== undefined && projectData !== undefined && !error.isError) {
        return (
            <div id="App">
                <DisclaimerModal disclaimer={disclaimer}/>
                <Grid
                    container
                    columns={1}
                    spacing={1}
                    sx={{
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}
                >
                    <Grid item>
                        <Chip variant='outlined'
                              label={projectData.name}
                              color='primary'
                              icon={<Tooltip title={disclaimer} placement='bottom' arrow><InfoIcon /></Tooltip>}
                              sx={{
                                  padding: 2,
                                  color: 'white',
                                  borderWidth: 2,
                                  borderRadius: 5
                              }}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12} sm={6} md={4} lg={2}
                    >
                        <FundingSource drawsData={drawsData} />
                    </Grid>
                    {/*bottom paper*/}
                    <Grid
                        item
                    >
                        <Grid
                            container
                            spacing={2}
                            columns={12}
                            justifyContent='center'
                        >
                            <DrawCards drawsData={drawsData} />
                        </Grid>
                    </Grid>
                </Grid>
                <Typography
                    fontSize={10}
                    color={'white'}
                    sx={{
                        m: 5,
                        display: "flex",
                        justifyContent: "center"
                    }}
                >
                    Quick Draw Fund Control, Inc. ® 2022 | All rights reserved
                </Typography>
            </div>
        );
    }
    // show circular progress until drawsData state updates
    else {
        if (!error.isError) {
            return (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )
        }
        // if an error occurs, display the error and prompt user to seek assistance
        else {
            return (
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item>
                        <Alert severity="error" sx={{width: 270, justifyContent: 'center'}}>
                            <AlertTitle>Error</AlertTitle>
                            {error.errorMessage}
                        </Alert>
                    </Grid>
                </Grid>
            )
        }
    }
}

export default App;
