
// API endpoints object
const API = {
    baseURL: "https://qdproxy.services",
    portalCompanyEndpoint: "portal/company",
    rabbetDrawsEndpoint: "rabbet/draws",
    rabbetProjectEndpoint: "rabbet/project"
}

// field for project id is coded, not named, for the client endpoint
// FIXME: this coded name can change, it may be best to get this from an env variable in docker
const projectIdAlias = "9ac61255-9182-4770-90f2-953b42166a95"

// generic fetch function
async function genericFetch(url, errorMessage) {
    const response = await fetch(url)
    if (response.ok) {
        return await response.json()
    }
    else if (response.status >= 400 && response.status <= 499) {
        throw 'Unexpected problem. Please contact Draw Portal admin for assistance.'
    }
    else {
        throw(errorMessage)
    }
}

// get client id => project id => draw data and project data
export function getProjectData(companyId, setDrawsData, setProjectData, setError) {

    try {

        const clientError = "Unable to retrieve client information. Please contact Draw Portal admin for assistance."
        const clients = genericFetch(`${API.baseURL}/${API.portalCompanyEndpoint}/${companyId}`, clientError)

        // chain async functions to get client's project id then draw data from Rabbet
        Promise.all([clients])
            .then(data => {

                console.log(data[0].data)
                // find project id from returned list of clients
                const allProjectIds = data[0].data.map(client => {
                    return(client.customFields[projectIdAlias])
                })
                // filter out null values
                const projectIds = allProjectIds.filter(id => id != null)
                // throw error if no project ID can be found
                if (projectIds < 1) {
                    throw('Unable to locate a Project ID for your company. Either your project has not been entered yet ' +
                    'or the Project ID has not been added your company account. If you believe this is a mistake, please ' +
                    'contact the Draw Portal admin via your Messages module.')
                }
                const drawsError = "Unable to retrieve draw information for your project. Please contact Draw Portal admin for assistance."
                const projectError = "Unable to retrieve project information. Please contact Draw Portal admin for assistance."

                // fetch rabbet data using project ID
                const drawData = genericFetch(`${API.baseURL}/${API.rabbetDrawsEndpoint}/${projectIds[0]}`, drawsError)
                const projectData = genericFetch(`${API.baseURL}/${API.rabbetProjectEndpoint}/${projectIds[0]}`, projectError)
                Promise.all([drawData, projectData])
                    .then(data => {
                        console.log(data[0].data)
                        console.log(data[1].data)
                        setDrawsData(data[0].data)
                        setProjectData(data[1].data)
                    })
                    .catch(error => {
                        setError({
                            isError: true,
                            errorMessage: error
                        })
                    })
            })
            .catch(error => {
                setError({
                    isError: true,
                    errorMessage: error
                })
            })
    }
    catch (error) {
        setError({
            isError: true,
            errorMessage: error
        })
    }
}